import Link from 'next/link';

const Custom404 = () => {
  return (
    <div className="container">
      <h1>404 - Page Not Found</h1>
      <p>Oops! The page you're looking for does not exist.</p>
      <p>Please check the URL or go back to the <Link href="/">homepage</Link>.</p>
      <style jsx>{`
        .container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100vh;
          text-align: center;
        }
        h1 {
          font-size: 48px;
          margin-bottom: 16px;
        }
        p {
          font-size: 18px;
          margin-bottom: 8px;
        }
      `}</style>
    </div>
  );
};

export default Custom404;
